import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import cx from "classnames";
import { Formik, Form } from "formik";
import Lottie from "react-lottie";

import style from "./ContactForm.module.scss";
import Textbox from "../../elements/Forms/Textbox";
import Textarea from "../../elements/Forms/Textarea";
import Button from "../../elements/Buttons/Button";
import { ContainerRow } from "../../atoms/Containers/Conainers";
import Checkbox from "../../elements/Forms/Checkbox";
import CentralLayout from "../../atoms/CentralLayout/CentralLayout";
import Space from "../../elements/Space/Space";
import Shape from "../../elements/Shape/Shape";
import * as successLottie from "./form-success.json";
import * as errorLottie from "./form-error.json";
import { Link } from "gatsby";

const ContactForm = ({ text_line1 = "Richiedi ora informazioni per diventare un nostro affiliato", text_line2 = "È gratuito e senza impegno!" }) => {
  const [result, setResult] = useState(null);
  useEffect(() => setResult(null), []);
  return (


    <section className={cx(style.formContainer)} id='contact-form'>
      {result === null && (
        <div className="relative">

          <Space mobile={15} table={15} desktop={20} />
          <CentralLayout>
            <h3 className='m-0'>{text_line1}</h3>
            <h4 className='mt-0 mb-6'>{text_line2}</h4>
          </CentralLayout>
          <ContainerRow>
            <div className={cx("col-12 offset-md-1 col-md-10", style.form)}>
              <Formik
                onSubmit={values => {
                  values.pagina = window.location.href;
                  console.log(values);
                  fetch("https://webhook.e-leads.it/mail", {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                      'Content-Type': 'application/json'
                    },
                  })
                    .then(result => {
                      setResult(true);
                    })
                    .catch(error => {
                      setResult(false);
                    });
                }}
                initialValues={{ societa: "", nominativo: "", email: "", telefono: "", sito: "", note: "", privacy: false }}
                validationSchema={Yup.object().shape({
                  societa: Yup.string().required(),
                  nominativo: Yup.string().required(),
                  email: Yup.string().email().required(),
                  telefono: Yup.string().required(),
                  sito: Yup.string(),
                  note: Yup.string(),
                  privacy: Yup.boolean().equals([true]),
                })}
              >
                {form => {
                  return (
                    <Form>
                      <ContainerRow>
                        <Textbox name='societa' placeholder='Società *' />
                        <Textbox name='nominativo' placeholder='Nome e cognome *' />
                        <Textbox name='email' placeholder='E-Mail *' cols={3} />
                        <Textbox name='telefono' placeholder='Telefono *' cols={3} />
                        <Textbox name='sito' placeholder='Sito web' cols={3} />
                        <Textarea name='note' placeholder='Di cosa vuoi parlarci?'></Textarea>
                        <Checkbox name={"privacy"} cols={2}>
                          Dichiaro di aver preso visione dell'<Link href='/Privacy-Policy-Sito-web'>informativa sulla privacy</Link>
                        </Checkbox>
                        <div className={cx("col-12 col-md-6 mt-4", style.right)}>
                          <Button type='submit'>Contattaci</Button>
                        </div>
                      </ContainerRow>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </ContainerRow>
          <Space mobile={15} table={15} desktop={20} />
        </div >
      )}
      {
        result && (
          <div className={cx(style.resultBoxContainer, "py-8")}>
            <div className={style.resultBox}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: successLottie.default,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
            </div>
            <h4 className='my-0'>Grazie per il tuo messaggio.</h4>
            <h4 className='my-0 mb-10'>A breve ti contatteremo.</h4>
          </div>
        )
      }
      {
        result === false && (
          <div className={cx(style.resultBoxContainer, "py-8")}>
            <div className={style.resultBox}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: errorLottie.default,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
            </div>
            <h4 className='my-0'>Qualcosa è andato storto nell’invio.</h4>
            <h4 className='my-0 mb-10'>Ti invitiamo a riprovare.</h4>
            <Button customClass='mt-10' black onClick={() => setResult(null)}>
              Contattaci
          </Button>
          </div>
        )
      }
    </section >
  );
};

export default ContactForm;
