import React from "react";
import cx from "classnames";
import { Field } from "formik";
import style from "./Textarea.module.scss";

const Textarea = ({ name, placeholder = "", cols = 1, type = "text" }) => {
  return (
    <div className={cx(style.inputWrapper, { "col-12": cols === 1 }, { "col-12 col-md-6": cols === 2 }, { "col-12 col-md-4": cols === 3 })}>
      <Field name={name}>{({ field, meta }) => <textarea className={cx(style.input, { [style.error]: meta.touched && meta.error })} placeholder={placeholder} {...field} type={type} />}</Field>
    </div>
  );
};

export default Textarea;
