export const scrollToContact = () => {
  const form = document.getElementById("contact-form");
  console.log("offset", form.firstChild.offsetParent.offsetTop);
  if (form) {
    window.scrollTo({ behavior: "smooth", top: form.firstChild.offsetParent.offsetTop - 60 });
  }
};


export const scrollToId = (id) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({ behavior: "smooth", top: element.offsetTop - 150 });
  }
};
