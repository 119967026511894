import React from "react";
import cx from "classnames";
import style from "./Shape.module.scss";

const Shape = ({position = "", direction = ""}) => (
  <div className={`${position} ${direction == "inverse" ? style.inverse : style.normal} ${style.wrapper}`}>
    <img src='/shape/5.svg' alt='' className={`${style.shapeThree} ${style.imgShape}`} />
    <img src='/shape/6.svg' alt='' className={`${style.shapeFour} ${style.imgShape}`} />
    <img src='/shape/8.svg' alt='' className={`${style.shapeSix} ${style.imgShape}`} />
    <img src='/shape/9.svg' alt='' className={`${style.shapeSeven} ${style.imgShape}`} />
    <img src='/shape/10.svg' alt='' className={`${style.shapeEight} ${style.imgShape}`} />
    <img src='/shape/11.svg' alt='' className={`${style.shapeNine} ${style.imgShape}`} />
    <img src='/shape/13.svg' alt='' className={`${style.shapeEleven} ${style.imgShape}`} />
    <img src='/shape/14.svg' alt='' className={`${style.shapeTwelve} ${style.imgShape}`} />
  </div>
);

export default Shape;
