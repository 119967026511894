import React, {useState} from "react";
import cx from "classnames";
import {Field} from "formik";
import style from "./Checkbox.module.scss";

const Checkbox = ({name, children, cols = 1, type = "text"}) => {
  const [value, setValue] = useState(false);
  return (
    <Field name={name}>
      {({meta, form}) => (
        <div className={cx(style.wrapper, {[style.error]: meta.touched && meta.error}, {"col-12": cols === 1}, {"col-12 col-md-6": cols === 2}, {"col-12 col-md-4": cols === 3})}>
          {/* eslint-disable */}
          <div
            className={cx(style.input, {[style.checked]: value})}
            onClick={() => {
              form.setTouched({...form.touched, [name]: true});
              form.setFieldValue(name, !value);
              setValue(prev => !prev);
            }}
          />
          <div>{children}</div>
        </div>
      )}
    </Field>
  );
};

export default Checkbox;
