import React from "react";
import cx from "classnames";
import {Field} from "formik";
import style from "./Textbox.module.scss";

const Textbox = ({name, placeholder = "", cols = 1, type = "text"}) => {
  return (
    <div className={cx(style.inputWrapper, {"col-12": cols === 1}, {"col-12 col-md-6": cols === 2}, {"col-12 col-md-4": cols === 3})}>
      <Field name={name}>{({field, meta}) => <input className={cx(style.input, {[style.error]: meta.touched && meta.error})} placeholder={placeholder} {...field} type={type} />}</Field>
    </div>
  );
};

export default Textbox;
